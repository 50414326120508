<template>
  <div class="product_aside">
    <h4 class="aside_title">Filter By Price</h4>
    <div>
      <vue-range-slider
        v-model="value"
        :min="min"
        @drag-end="priceSearch"
        :max="max"
        :enable-cross="enableCross"
      />
    </div>
    <div class="product_menu">
      <div class="price_filter">
        <div id="slider-range"></div>
        <div class="price_value">
          <div class="price">
            Price: <span id="slider-range-value1">{{value[0]}}</span> TK
          </div>
          <div class="price">
            Price: <span id="slider-range-value2">{{value[1]}}</span> TK
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueRangeSlider from "vue-range-component";
import {mapGetters} from "vuex";
export default {
  components: {
    VueRangeSlider,
  },
  data() {
    return {
      value: [0, 5000],
    };
  },
  created() {
    this.min = 0;
    this.max = 5000;
    this.enableCross = false;
    VueRangeSlider.methods.handleKeyup = () => console.log;
    VueRangeSlider.methods.handleKeydown = () => console.log;
  },
  computed: {
    ...mapGetters(["category"]),
  },
  methods: {
    priceSearch() {
      // let queryParams = this.$route.query
      //console.log("0000000==>",this.$route.query);
      //console.log("00000001==>",this.category);
      // queryParams.price = this.value[1]
      let path_name = "category";
      let cat = this.category;
      this.$router
        .push({
          name: path_name,
          query: {category:cat.id, ...this.$route.query, min_price:this.value[0], max_price:this.value[1] },
        })
        .catch(() => {});
      //console.log("Category Price Route", this.$route.query)
      //const queryString = new URLSearchParams(this.$route.query).toString()

      //this.$store.dispatch("getCatagory", this.$route.params.slug + "?" + queryString)

      // this.$router.push( {  query: queryParams } )
      // .then(()=>{ this.$router.replace({ path:'category', param:{slug: 'Lipstick'}, query: queryParams } )})
      // .catch(()=>{})

      //console.log('price filter', this.$route.params );
    },
  },
};
</script>

<style></style>
