<template>
  <div class="col-lg-3 col-md-4 aside-rel">
    <div class="fix-aside">
      <FilterByPrice></FilterByPrice>
      <FilterByCategory></FilterByCategory>
      <FilterByBrand :brands="brands"></FilterByBrand>
    </div>
  </div>
</template>

<script>
import FilterByPrice from "@/components/frontend/category/filter_by/FilterByPrice";
import FilterByCategory from "@/components/frontend/category/filter_by/FilterByCategory";
import FilterByBrand from "@/components/frontend/category/filter_by/FilterByBrand";
export default {
  props: ["brands"],
  components: {
    FilterByPrice,
    FilterByCategory,
    FilterByBrand,
  },
};
</script>

<style>
  .fix-aside{
    position: sticky;
    top: 150px;
  }
</style>
