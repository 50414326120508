<template>
    <div class="product_aside">
        <h4 class="aside_title">Filter By Brand</h4>
        <div class="product_menu">
            <form class="search_form" @submit.prevent="noUrl()">
                <div class="form-group">
                    <input type="text" name="search_list" v-model="filterBrand"/>
                    <button type="submit" class="sendBtn">
                        <i class="icon ion-md-search"></i>
                    </button>
                </div>
            </form>
            <ul class="menu_list">
                <!--<li v-for="brand in category.brands.filter((e, i) => category.brands.findIndex(a => a[name] === e[name]) === i)" :key="brand.id" @click="filterBybrand(brand)">
                  &lt;!&ndash; <router-link :to="{ query:{ brand: brand.id } }">{{
                    brand.name
                  }}</router-link> &ndash;&gt;
                  &lt;!&ndash;{{ brand.name }}&ndash;&gt;
                  {{brand.name}}
                </li>-->
                <li v-for="brand in catlist" :key="brand.id" @click="filterBybrand(brand)">
                    {{brand.name}}
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import axios from "../../../../axios";

    export default {
        props: ["brands"],
        data() {
            return {
                filterBrand: '',
                page: 1,
                uniqueBrand: [],
                catlist: []
            }
        },

        watch: {
            $route (){
                this.filterBrand = '';
            },
            filterBrand(val) {
                if (val) {
                    console.log("category=", this.category);
                    console.log("val=", val);
                    let catFilter = this.category.id + '?keyword=' + val
                    axios.get(`/brand-filter-in-category/${catFilter}`).then((response) => {
                        console.log("####", response.data.data);
                        const key = 'id';
                        const unique = [...new Map(response.data.data.map(item =>
                            [item[key], item])).values()];
                        this.catlist = unique;
                    })
                } else {
                    this.catlist= [];
                    let catFilter = this.category.id + '?keyword=' + ''
                    axios.get(`/brand-filter-in-category/${catFilter}`).then((response) => {
                        console.log("####2", response.data.data);
                        const key = 'id';
                        const unique = [...new Map(response.data.data.map(item =>
                            [item[key], item])).values()];

                        this.catlist = unique;
                        console.log("####3", unique);
                    });

                    console.log("elseeeeeeeeeeeeee=", val);
                    //this.catlist = this.category.brands;
                }

            },
        },
        computed: {
            ...mapGetters(["category"]),
        },
        mounted() {
            //this.$store.dispatch("getCatagory", this.$route.params.slug);
            this.catlist = this.category.brands;
        },
        methods: {
            filterBybrand(brand) {
                this.$store.dispatch("setPage", this.page);
                let path_name = 'category'
                this.$router.push({
                    name: path_name,
                    query: {...this.$route.query, page: this.page, brand: brand.id}
                }).catch(() => {
                })
            },
            noUrl(){
                event.preventDefault();
            }
        }
    };
</script>

<style>
    ul.menu_list li {
        cursor: pointer;
    }
</style>
