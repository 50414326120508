<template>
  <section class="category_section">
    <div class="container">
      <div class="row">
        <ProductAside :brands="brands" class="mobile-hide "></ProductAside>
        <div class="col-lg-9 col-md-8">
          <div class="product_search">
            <div class="search form">
              <p>ITEMS: </p>
              <form class="search_form" @submit.prevent="searchProduct(searchInput)">
                <div class="form-group">
                  <input type="text" name="search" v-model="searchInput" placeholder="Type here to search" />
                  <button type="submit" class="sendBtn">
                    <i class="icon ion-md-search"></i>
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div v-if="allCatProduct.length > 0" class="row product_row">
            <!--class="col-xl-3 col-lg-3 col-sm-6"-->
            <!--=====
            <pre>{{category}}</pre>
            =====-->
            <!--<div v-for="product in products.filter((e) => e.product_type.length > 0 )" :key="product.id" class="col-6 col-md-3">-->

            <div v-for="product in allCatProduct" :key="product.id" class="col-6 col-md-3">
              <div class="product_area product-area-wrap">
                <figure v-if="product.product_images.length > 0" class="product_img">
                  <router-link :to="{ name: 'product', params: { slug: product.slug, type: product.product_type } }"
                    target='_blank'>
                    <img v-if="product.product_images"
                      :src="'https://savershall.com/suadmin/' + product.product_images[0].image" alt="" />
                  </router-link>
                  <span v-if="product.product_type === 1 && product.product_price[0].quantity === 0"
                    class="sold-out">Sold Out!</span>
                  <a v-if="!product.is_wishlist" @click="addWishList(product)" class="favorit">
                    <i class="icon ion-md-heart"></i>
                    <i class="icon ion-md-heart-empty"></i>
                  </a>
                  <a v-if="product.is_wishlist" class="favorit">
                    <i class="icon ion-md-heart" style="display: block !important"></i>
                  </a>
                </figure>
                <!--<pre>{{product}}</pre>-->
                <div v-if="product.product_type === 1 || product.product_type === 3" class="product_title">
                  <router-link :to="{ name: 'product', params: { slug: product.slug, type: product.product_type } }"
                    target='_blank'>
                    <h4>{{ product.name }}</h4>
                  </router-link>
                  <!-- <a href=""><h4>{{ product.name }}</h4></a> -->
                  <div class="">
                    <span class="product-price" v-if="product.product_price">
                      <p>
                        Was: <del v-if="product.product_price[0].offer_price">
                          {{ product.product_price[0].price }}৳</del>
                      </p>

                      <p v-if="!proPrice">
                        Now: {{
                          product.product_price[0].offer_price
                            ? product.product_price[0].offer_price
                            : product.product_price[0].price
                        }}৳ (
                        {{ percentCalc(product.product_price[0].price,
                          product.product_price[0].offer_price
                            ? product.product_price[0].offer_price
                            : product.product_price[0].price) }}%
                        ) <small>/piece</small>
                      </p>

                      <span v-if="proPrice">Now :{{ proPrice }}৳</span>
                      <!--{{
                      product.product_price[0].offer_price
                      ? product.product_price[0].offer_price
                      : product.product_price[0].price
                      }}-->


                    </span>
                  </div>

                  <div v-if="$store.getters.isExist(product.id)" class="product_action">
                    <button class="minus" @click="deductQty(product)">
                      <i class="icon ion-md-remove"></i>
                    </button>
                    <div class="product_value">
                      <span class="quantity_value">
                        {{ $store.getters.prodQty(product.id) }}
                      </span>
                      <small>Product added to your Basket</small>
                    </div>
                    <button :disabled="product.product_price[0].quantity ===
                      $store.getters.prodQty(product.id)
                      " class="plus" @click="addQty(product)">
                      <i class="icon ion-md-add"></i>
                    </button>
                  </div>
                  <div v-if="
                    !$store.getters.isExist(product.id) &&
                    product.product_price[0].quantity > 0
                  " class="product_action">
                    <button style="width: 100%" class="plus product-add-btn" @click="addToBag(product)">
                      Add to bag
                    </button>
                  </div>
                  <div v-if="
                    !$store.getters.isExist(product.id) &&
                    product.product_price[0].quantity <= 0
                  " class="product_action">
                    <button style="width: 100%" class="plus product-add-btn" @click="notifyMe(product)">
                      Sold Out. Notify Me
                    </button>
                  </div>
                </div>
                <div v-if="product.product_type === 2" class="product_title">
                  <!--<pre>{{product}}</pre>-->
                  <router-link class="title-height"
                    :to="{ name: 'product', params: { slug: product.slug, type: product.product_type }, }">
                    <h4>{{ product.name | truncate(78, '...') }}</h4>
                  </router-link>
                  <p v-if="product.min_price">
                    <span v-if="!proPrice">
                      <span v-if="product.min_offer_price > 0">Start At: ৳
                        {{ product.min_offer_price ? product.min_offer_price : '' }} TK &nbsp;<del>৳ {{
                          product.min_offer_price ? product.min_price : '' }} TK</del></span>
                      <span v-else>Start At: ৳ {{ product.min_price }} TK</span>
                    </span>
                    <span v-else="">৳ {{ product.min_price }} - {{ product.max_price }} TK</span>
                    <span v-if="proPrice">{{ proPrice }} TK</span>
                  </p>
                  <div class="product_action action-details">
                    <router-link target='_blank' class="details"
                      :to="{ name: 'product', params: { slug: product.slug, type: product.product_type }, }">
                      Details
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <!--<NotifyModal v-model="showModal" :product="notifyMeData" :modalView="showModal"></NotifyModal>-->
            <div v-if="showModal">
              <transition name="modal">
                <div class="modal-mask">
                  <div class="modal-wrapper">
                    <div class="modal-dialog" role="document">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h5 class="modal-title">{{ notifyMeData.name }}</h5>
                          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true" @click="showModal = false">&times;</span>
                          </button>
                        </div>
                        <div class="modal-body">
                          <div class="form-group" style="margin-bottom: 1rem">
                            <input type="text" class="form-control" v-model="name" placeholder="Your Name" />
                          </div>
                          <div class="form-group">
                            <input type="text" class="form-control" v-model="mobileNo"
                              placeholder="Your Mobile Number" />
                          </div>
                        </div>
                        <div class="modal-footer">
                          <button type="button" class="btn btn-secondary" @click="showModal = false">
                            Close
                          </button>
                          <button type="button" class="btn btn-primary" @click="
                            notifyMeForSubmit(name, mobileNo, notifyMeData)
                            ">
                            Notify Me
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </transition>
            </div>
          </div>
          <div class="text-center">
            <!-- <pre>{{ totalProduct }}/{{ allCatProduct.length }}</pre> -->
            <button type="button" class="btn btn-dark" style=" padding: 5px 15px"
              v-if="totalProduct === allCatProduct.length ? false : true" @click="loadMoreProduct">LOAD MORE</button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ProductAside from "@/components/frontend/category/ProductAside.vue";
//import SearchBy from "@/components/frontend/category/SearchBy.vue";
import { mapGetters } from "vuex";
import axios from "../../../axios";
!function (f, b, e, v, n, t, s) {
  if (f.fbq) return; n = f.fbq = function () {
    n.callMethod ?
      n.callMethod.apply(n, arguments) : n.queue.push(arguments)
  };
  if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0';
  n.queue = []; t = b.createElement(e); t.async = !0;
  t.src = v; s = b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t, s)
}(window, document, 'script',
  'https://connect.facebook.net/en_US/fbevents.js');
window.fbq('init', '865834318321102');
window.fbq('track', 'PageView', { value: 0, currency: 'BDT' });
export default {
  name: "CategorySection",
  props: ["brands", "products"],
  data() {
    return {
      showModal: false,
      notifyMeData: "",
      mobileNo: "",
      name: "",
      totalProduct: 0,
      page: 1,
      proPrice: 0,
      allCatProduct: [],
      searchInput: ''
    };
  },
  components: {
    ProductAside,

  },
  computed: {
    ...mapGetters(["shoppingBag", "accessToken", "category", "getPage", "loadMorePage"]),
  },
  watch: {
    $route() {
      window.scrollTo(0, 0);
      console.log("Route Cat =", this.$route.fullPath)
      this.$store.dispatch("getCatagory", this.$route.params.slug);
      this.$store.dispatch("getBrand", this.$route.fullPath);
      this.$store.dispatch('loadMorePage', 1);
      axios.get(this.$route.fullPath )
        .then(response => {
          this.totalProduct = response.data.data.total_product;
          this.allCatProduct = response.data.data.products
        });
    },
    searchInput(val) {
      if (val) {
        this.$store.dispatch('loadMorePage', 1);
        let cser = '';
        if (this.$route.fullPath.includes('?')) {
          cser = this.$route.fullPath + '&name=' + val;
        } else {
          cser = this.$route.fullPath + '?name=' + val;
        }
        axios.get(cser)
          .then(response => {
            console.log("Search pro", response)
            this.totalProduct = response.data.data.total_product;
            this.allCatProduct = response.data.data.products
          });
      } else {
        axios.get(this.$route.fullPath + '?page=' + 1)
          .then(response => {
            this.totalProduct = response.data.data.total_product;
            this.allCatProduct = response.data.data.products
          });
      }
    }
  },
  mounted() {
    console.log("")
    window.scrollTo(0, 0);
    //this.$store.dispatch("getBrand", this.$route.fullPath);
    this.$store.dispatch('loadMorePage', 1);
    axios.get(this.$route.fullPath + '?page=' + 1)
      .then(response => {
        this.totalProduct = response.data.data.total_product;
        this.allCatProduct = response.data.data.products
      });
  },
  methods: {
    addToBag(product) {
      product.cartImage = product.product_images[0].image;
      product.qty = 1;
      product.product_price[0].offer_price > 0
        ? (product.unitPrice = product.product_price[0].offer_price)
        : (product.unitPrice = product.product_price[0].price);

      this.$store.dispatch("addToBag", product);

    },
    addQty(product) {
      // console.log('update', product.product_price[0].tier_quantity_three)
      this.$store.dispatch("addQty", product);
      /*if (product.qty <= product.product_price[0].quantity) {
        if (
          product.qty >= product.product_price[0].tier_quantity_three &&
          product.product_price[0].tier_quantity_three !== 0
        ) {
          this.proPrice = product.product_price[0].tier_price_three;
        } else if (
          product.qty >= product.product_price[0].tier_quantity_two &&
          product.qty < product.product_price[0].tier_quantity_three
        ) {
          this.proPrice = product.product_price[0].tier_price_two;
        } else if (
          product.qty >= product.product_price[0].tier_quantity_one &&
          product.qty < product.product_price[0].tier_price_two
        ) {
          this.proPrice = product.product_price[0].tier_price_one;
        } else if (product.product_price[0].offer_price) {
          this.proPrice = product.product_price[0].offer_price;
        } else {
          this.proPrice = product.product_price[0].price;
        }
      }*/
    },
    deductQty(product) {
      if (this.$store.getters.prodQty(product.id) > 0) {
        this.$store.dispatch("deductQty", product);
      }
      if (this.$store.getters.prodQty(product.id) == 0) {
        this.$store.dispatch("removeFromCart", product);
      }
      /*if (product.qty <= product.product_price[0].quantity) {
        if (
          product.qty >= product.product_price[0].tier_quantity_three &&
          product.product_price[0].tier_quantity_three !== 0
        ) {
          this.proPrice = product.product_price[0].tier_price_three;
        } else if (
          product.qty >= product.product_price[0].tier_quantity_two &&
          product.qty < product.product_price[0].tier_quantity_three
        ) {
          this.proPrice = product.product_price[0].tier_price_two;
        } else if (
          product.qty >= product.product_price[0].tier_quantity_one &&
          product.qty < product.product_price[0].tier_price_two
        ) {
          this.proPrice = product.product_price[0].tier_price_one;
        } else if (product.product_price[0].offer_price) {
          this.proPrice = product.product_price[0].offer_price;
        } else {
          this.proPrice = product.product_price[0].price;
        }
      }*/
    },
    notifyMe(data) {
      this.notifyMeData = data;
      this.showModal = true;
    },
    notifyMeForSubmit(name, phone, product) {
      if (name !== "" && phone !== "" && product.id) {
        let notifyDataForPass = {
          name: name,
          phone: phone,
          product_id: product.id,
        };
        this.$store
          .dispatch("soldOutNotifyMe", notifyDataForPass)
          .then((res) => {
            console.log("Response Data = ", res);
            if (res === undefined) {
              window.alert("Successfully Submitted.");
            }
          });
        console.log("Data for pass: ", notifyDataForPass);
      }
      this.showModal = false;
    },
    addWishList(product) {
      if (this.accessToken !== null) {
        let productId = {
          product_id: product.id,
        };
        this.$store.dispatch("addToWishlist", productId).then((res) => {
          if (res === undefined) {
            window.alert("Added to Wishlist.");
            product.is_wishlist = 1;
          }
        });
      } else {
        this.$router.push({ name: "login" }).catch(() => { });
      }
    },
    loadMoreProduct() {
      let page = parseInt(this.loadMorePage) + 1;
      this.$store.dispatch('loadMorePage', page);
      console.log("Page = ", page);
      console.log('searchInput=', this.$route.fullPath);

      let url = this.$route.fullPath;
      // Check if there are query parameters
      if (url.includes('?')) {
        url += '&';
      } else {
        url += '?';
      }

      if (this.searchInput) {
        url += 'name=' + encodeURIComponent(this.searchInput) + '&';
      }

      url += 'page=' + page;

      axios.get(url)
        .then(response => {
          response.data.data.products.forEach(item => {

            // for duplicate
            // let duplicate = 0;
            // const existingItem = this.allCatProduct.find(product => product.id === item.id);
            // if (!existingItem) {
            //   // If the item does not exist, push it to the array
            //   this.allCatProduct.push(item);
            //   // this.totalProduct +=1
            // } else {
            //   duplicate += 1;
            // }
            // this.totalProduct = this.totalProduct - duplicate;

            this.allCatProduct.push(item);
          });
        })
        .catch(error => {
          console.error('Error loading more products:', error);
        });
    },
    // loadMoreProduct() {
    //   let page = parseInt(this.loadMorePage) + 1;
    //   this.$store.dispatch('loadMorePage', page);
    //   console.log("Page = ", page);
    //   console.log('searchInput=', this.$route.fullPath);

    //   if (this.$route.fullPath.includes('?')) {
    //     axios.get(this.$route.fullPath + '?name=' + this.searchInput + '&page=' + page)
    //       .then(response => (
    //         response.data.data.products.forEach(item => {
    //           this.allCatProduct.push(item);
    //         })
    //       ));
    //   } else {
    //     axios.get(this.$route.fullPath + '?page=' + page)
    //       .then(response => (
    //         response.data.data.products.forEach(item => {
    //           this.allCatProduct.push(item);
    //         })
    //       ));
    //   }

    // },
    /*loadMore() {
      this.page = this.getPage;
      this.page++;
      this.$store.dispatch("setPage", this.page);
      console.log("Route =>", this.$route);
      console.log("Slug =>", this.$route.params.slug);
      let path_name = "category";
      this.$router
        .push({
          name: path_name,
          query: { ...this.$route.query, page: this.page },
        })
        .catch(() => {});
    },*/
    percentCalc(wasVal, newVal) {
      if (newVal && wasVal) {
        var per = (newVal * 100) / wasVal;
        return Math.round(100 - per);
      }
    },
    searchProduct(searchInput) {
      let ser = '';
      let url = this.$route.fullPath;
      if (url.includes('?')) {
        ser = this.$route.fullPath + '&name=' + searchInput;
      } else {
        // ser = this.$route.fullPath + '?name=' + searchInput;
      }
      if (searchInput) {
        axios.get(ser)
          .then(response => {
            //console.log("Alll ===>", response.data.data)
            this.totalProduct = response.data.data.total_product;
            this.allCatProduct = response.data.data.products;
          });
        //this.$store.dispatch("getCatagory", `/category/${ser}`);
      }


    },

  },
};
</script>

<style scoped>
.product-price p {
  margin-bottom: 0px;
}

.product-price small {
  color: #aaa;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.icon {
  cursor: pointer;
}

.load_more {
  cursor: pointer;
}

.product_title h4 {
  height: auto !important;
}

.product_title a h4 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.product-area-wrap:hover .product_title a h4 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 30;
  -webkit-box-orient: vertical;
}

.product_action.action-details {
  text-align: center;
}

a.details {
  display: block;
  width: 100%;
  background: #434343;
  color: #ffffff;
  padding: 8px;
}

a.title-height {
  min-height: 60px;
  display: block;
}

/*Media Queries start here*/
/*Extra small devices (portrait phones, less than 576px)*/
@media (max-width: 575.98px) {
  .product_title h4 {
    font-size: 0.8rem;
    height: 35px;
  }
}

@media (min-width: 100px) and (max-width: 991.99px) {
  .mobile-hide {
    display: none;
  }
}
</style>
