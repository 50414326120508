<template>
  <div v-if="category.sub_categories.length > 0" class="product_aside">
    <h4 class="aside_title">Filter By Category</h4>
    <div class="product_menu">
      <form class="search_form" @submit.prevent="noUrl()">
        <div class="form-group">
          <input type="text" v-model="catfilter" name="search_list" />
          <button type="button" class="sendBtn">
            <i class="icon ion-md-search"></i>
          </button>
        </div>
      </form>
      <ul class="menu_list">
        <!--<li>
          <a href=""></a>
        </li>-->
        <li v-for="cat in catlist" :key="cat.id" @click="filterByCat(cat)">
          <!-- <router-link :to="{ query:{ brand: brand.id } }">{{
            brand.name
          }}</router-link> -->
          {{ cat.name }}
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
  import { mapGetters } from "vuex";
  import axios from "../../../../axios";
  export default {
    data(){
      return{
        showModal:false,
        notifyMeData:'',
        mobileNo:'',
        name:'',
        page:1,
        catfilter:'',
        catlist:''
      }
    },
    props: ["brands"],
    computed: {
      ...mapGetters(['category','categoryByTextFilter']),
    },
    async mounted(){
      //this.$store.dispatch("getCatagory", this.$route.params.slug);
      this.catlist = this.category.sub_categories;
    },
    watch:{
      $route (){
        this.catfilter = '';
      },
      '$store.state.category': function(){
        this.catlist = this.category.sub_categories;
      },
      catfilter(val) {
        if(val){
          //this.$store.dispatch("getCategoryByTextFiltering", this.category.id+'?keyword='+val.toUpperCase());
          let catFilter = this.category.id+'?keyword='+val
          axios.get(`/category-filter-in-category/${catFilter}`).then((response) => {
            this.catlist = response.data.data;
          })
        }else {
          this.catlist = [];
          let catFilter = this.category.id+'?keyword='+''
          axios.get(`/category-filter-in-category/${catFilter}`).then((response) => {
            this.catlist = response.data.data;
          });
          //this.catlist = this.category.sub_categories;
          console.log("Filter else", this.catlist);
        }

      },
    },
    methods: {
      filterByCat(category){
        this.$store.dispatch("setPage", this.page);
        //let path_name =  'category';
        //this.$router.push({name: path_name, query: {...this.$route.query, page:this.page, category: category.id}}).catch(()=>{})
        this.$router.push('/category/'+category.slug).catch(()=>{});
        const queryString = new URLSearchParams(this.$route.query).toString()
        this.$store.dispatch("getCatagory", this.$route.params.slug + "?" + queryString);

        // this.$store.dispatch()
      },
      noUrl(){
        event.preventDefault();
      }
    }
  };
</script>
<!--<script>
export default {};
</script>-->

<style>
  .menu_list li a{
    cursor: pointer;
  }
</style>
