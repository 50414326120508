<template>
    <div>
        <SecondHeader></SecondHeader>
        <CategorySection
                :products="category.products"
                :brands="category.brands"
        >
        </CategorySection>

    </div>
</template>


<script>
  import {mapGetters} from "vuex";
  import SecondHeader from "@/components/frontend/category/SecondHeader.vue";
  import CategorySection from "@/components/frontend/category/CategorySection.vue";

  export default {
    name: "Category",
    data() {
      return {
        page:1,
        catagoryData:[]
      };
    },
    metaInfo() {
      return {
        title: this.category.meta_title,
        titleTemplate: '%s | Savers Hall',
        meta: [
          {
            name: 'description',
            content: this.category.meta_description
          },
          {
            name: 'keyword',
            content: this.category.meta_keyword
          }
        ]
      }
    },
    components: {
      SecondHeader,
      CategorySection,
    },
    watch: {
      $route() {
        this.$store.dispatch("getCatagory", this.$route.fullPath);

      }
    },
    computed: {
      ...mapGetters(["category"]),
    },
    mounted() {
      this.$store.dispatch("getCatagory", this.$route.fullPath);
    },

  };
</script>
<style lang="scss">
    @import "@/assets/frontend/style/category.scss";
</style>
