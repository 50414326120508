<template>
  <header class="secound_header">
    <div class="container">
      <!-- <p>Shopping bag {{cartActiveStatus}}</p> -->
      <div v-if="category.banner_photo" class="header_img cat-header-img">
        <img :src="'https://savershall.com/suadmin/'+category.banner_photo" :alt="category.banner_photo_description" />
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["cartActiveStatus","category"]),
  },
};
</script>

<style></style>
